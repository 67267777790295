import { SET_TASK, SELECTED_TASK } from '../actions/taskActions';

const initialState = {
  disable_download: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TASK:
      let data = action.data;

      return {
        ...data,
      };
    case SELECTED_TASK:
      return state;
    default:
      return state;
  }
}
