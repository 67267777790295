import React, { useState, useEffect } from 'react';
import { accountService } from '~root/_services';
import Select from 'react-select';
import { Phone, Close, Send } from '@material-ui/icons';
import moment from 'moment';
import 'moment/locale/pl';
moment.locale('pl');

const Ava = `${process.env.PUBLIC_URL}/img/avatar3.png`;

const Chat = () => {
  const user = accountService.userValue;
  const [ws, setWs] = useState(null);
  const [onlinePeople, setOnlinePeople] = useState({});
  const [options, setOptions] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [showChat, toogleShowChat] = useState(true);
  const [message, setMessage] = useState('');
  const [messageList, updateMessageList] = useState([]);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSendMessage = (event) => {
    event.preventDefault();
    ws.send(
      JSON.stringify({
        recipient: selectedUserId,
        text: message,
        _id: Date.now(),
      })
    );
    if (user.id !== selectedUserId) {
      updateMessageList((prev) => [
        ...prev,
        {
          text: message,
          sender: user.id,
          recipient: selectedUserId,
          _id: Date.now(),
        },
      ]);
    }
    setMessage('');
  };
  useEffect(() => {
    accountService.getChatMessages(selectedUserId).then((res) => {
      updateMessageList(res);
    });
  }, [selectedUserId]);

  useEffect(() => {
    //ws start
    document.cookie = `token=${user?.jwtToken}; SameSite=Strict`;
    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'
    const ws = new WebSocket(`${protocol}://${process.env.REACT_APP_DOMAIN}`);
    setWs(ws);

    ws.addEventListener('message', handleMessage);
    //ws end
  }, []);
  const showOnlinePeople = (peopleArray) => {
    const people = {};
    peopleArray.forEach(({ userId, username }) => {
      people[userId] = username;
    });
    setOnlinePeople(people);
    const options = peopleArray.map(function ({ userId, username }) {
      return { value: userId, label: username };
    });
    if (user?.role === 'Onboarder') {
      const managerChat = [
        { value: user.manager.id, label: user.manager.name },
      ];
      setOptions(managerChat);
    } else if (user?.role === 'Manager' || user?.role === 'Admin') {
      accountService.getAllUsers().then((res) => {
        const allPeople = res.map(function ({ id, name }) {
          return { value: id, label: name };
        });
        setOptions(allPeople);
      });
    } else {
      setOptions(options);
    }
  };
  const handleMessage = (ev) => {
    const messageData = JSON.parse(ev.data);
    if ('online' in messageData) {
      showOnlinePeople(messageData.online);
    } else if ('text' in messageData) {
      updateMessageList((prev) => [...prev, { ...messageData }]);
    }
  };
  // useEffect(() => {
  // }, [messageList]);

  const selectContact = (data) => {
    setSelectedUserId(data.value);
  };

  return (
    <>
      {showChat ? (
        <div className='chat-box'>
          <div className='chat-box-header'>
            Chat {selectedUserId && <>z {onlinePeople[selectedUserId]}</>}
            <span
              className='chat-box-toggle'
              onClick={() => toogleShowChat(!showChat)}
            >
              <Close />
            </span>
          </div>

          <Select
            placeholder='Wybierz użytkownika chatu'
            options={options}
            onChange={selectContact}
            styles={{
              option: (base) => ({
                ...base,
                cursor: 'pointer',
              }),
            }}
          />
          <div className='chat-box-body'>
            <div className='chat-box-overlay'></div>
            <div className='chat-logs'>
              {!!selectedUserId &&
                messageList.map((message) => (
                  <div className='chat-block'>
                    <div className='cm-msg-date'>
                      {moment(message.createdAt).format('dddd, MMMM, HH:mm:ss')}
                    </div>

                    <div
                      key={message._id ? message._id : message.id}
                      id='cm-msg-1'
                      className={`chat-msg ${
                        user.id === message.sender ? 'self' : 'user'
                      }`}
                    >
                      <span className='msg-avatar'>
                        <img
                          src={
                            user.avatar
                              ? process.env.REACT_APP_SERVER_URL +
                                '/file/get/' +
                                user.avatar
                              : Ava
                          }
                        />
                      </span>
                      <div className='cm-msg-text'>{message.text}</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {!!selectedUserId && (
            <div className='chat-input'>
              <form onSubmit={handleSendMessage}>
                <input
                  type='text'
                  id='chat-input'
                  placeholder='Send a message...'
                  value={message}
                  onChange={handleChange}
                />
                <button className='chat-submit' id='chat-submit'>
                  <Send />
                </button>
              </form>
            </div>
          )}
        </div>
      ) : (
        <div
          id='chat-circle'
          className='btn btn-raised'
          onClick={() => toogleShowChat(!showChat)}
        >
          <div id='chat-overlay'></div>
          <Phone />
        </div>
      )}
    </>
  );
};

export default Chat;
