export const SET_TASK = 'SET_TASK';
export const SELECTED_TASK = 'SELECTED_TASK';
export const REMOVE_SELECTED_TASK = 'REMOVE_SELECTED_TASK';

export const setTask = (taskData) => {
  return {
    type: SET_TASK,
    payload: taskData,
  };
};

export const selectedTask = (task) => {
  return {
    type: SELECTED_TASK,
    payload: task,
  };
};
